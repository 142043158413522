<template>
  <div class="protocol-content">
    <p style="text-align: justify" v-for="protocol in protocols"> {{ protocol }}</p>
  </div>
</template>

<script>
  export default {
    name: "ProtocolContent",
    props: {
      protocols: {
        type: Array,
        default: ()=> [
          `مەزكۇر سۇپىغا دۆلىتىمىزنىڭ ئالاقىدار قانون نىزاملىرىغا شۇنداقلا يەرلىك سىياسەتكە قارشى ھەرقانداق شەكىلدىكى مەزمۇننى يوللاش چەكلىنىدۇ. `,
          `نەشىر ھوقوقى يوق بولغان مەزمۇنلارنى يوللاپ كېلىپ چىققان قانونى مەسئۇلىيەت ۋە ئىقتىسادىي ماجراغا نىسبەتەن ئاچقۇچى تەرەپ ھىچقانداق قانونىي ۋە ئىقتىسادى مەسئۇلىيەتنى ئۈستىگە ئالمايدۇ.`,
          `سېرىق تۈستىكى مەزمۇنلارنى يوللاپ كېلىپ چىققان قانونى مەسئۇلىيەت ۋە باشقا ماجرالارغا نىسبەتەن ئاچقۇچى تەرەپ ھىچقانداق قانونىي ۋە ئىقتىسادى مەسئۇلىيەتنى ئۈستىگە ئالمايدۇ.`,
          `جەمىئەتتە يامان تەسىر، خاتا كۆز قاراش، خاتا مۇنازىرە پەيدا قىلىدىغان مەزمۇنلارنى يوللاپ كېلىپ چىققان قانونى مەسئۇلىيەت ۋە ئىقتىسادىي ماجراغا نىسبەتەن ئاچقۇچى تەرەپ ھىچقانداق قانونىي ۋە ئىقتىسادى مەسئۇلىيەتنى ئۈستىگە ئالمايدۇ.`,
          `سۇپىنىڭ ئىشلىتىش ۋە باشقۇرۇش ھوقوقى ئىشلەتكۈچى تەرەپتە بولۇپ ئاچقۇچى تەرەپ ھەرقانداق قەدەم باسقۇچقا ئارلاشمايدۇ، يوللانغان بارلىق مەزمۇن پەقەت ئىشلەتكۈچى تەرەپنىڭ قىزىقىشى ۋە مەيدانىنى ئىپادىلەيدۇ، ئاچقۇچى تەرەپنىڭ قىزىقىشى ۋە مەيدانى بىلەن مۇناسىۋەتسىز.`,
          `تور دۇنياسى قانون سىرتىدىكى دۇنيا ئەمەس، ئۇمۇ قانوننىڭ بىردەك قوغداش ۋە جازالاش دائىرىسىدىكى ئاممىۋىي سورۇن بولۇپ، ئىشلەتكۈچى تەرەپ مۇناسىۋەتلىك قانون تۈزۈم ۋە سىياسەتكە بوي سۇنغان ئاساستا مەزكۇر سۇپىنى ئىشلىتىشى كېرەك، قانون تۈزۈم ۋە سىياسەتتىن چەتنەپ كەتكەن، قانونسىز، ئەخلاقسىز، سېرىق تۈستىكى مەزمۇنلارنى يوللاش پۈتۈنلەي چەكلىنىدۇ.`,
          `ئۈستىدە دىيىلگەن ۋە دىيىلمىگەن ھەرقانداق شەكىلدىكى قانونغا ، جامائەت ئەخلاقىغا خىلاپ بولغان مەزمۇنلارنى يوللاش بىردەك چەكلىنىدۇ، ئەگەر شۇنداق مەزمۇنلار يوللىنىپ قالسا بۇ پۈتۈنلەي ئىشلەتكۈچى تەرەپ بىر تەرەپنىڭ مەسئۇلىيىتى، ئاچقۇچى تەرەپ بىلەن مۇناسىۋەتسىز.`,
          `مەزكۇر سۇپىنى ئاچقۇچى تەرەپ، مەزمۇنغا قارىتا قەرەلسىز ھالدا تەكشۈرۈپ تۇرۇشقا ھوقوقلۇق، ناۋادا ئاچقۇچى تەرەپ بىنورمال مەزمۇننىڭ بارلىقى بايقىسا، ئىشلەتكۈچى تەرەپنى خەۋەرلەندۈرۈپ قويمايلا تازلىۋېتىشكە ھوقوقلۇق.`,
          `قانونسىز مەزمۇن يوللاپ كېلىپ چىققان ھەرقانداق ئاقىۋەتكە پەقەت ئىشلەتكۈچى تەرەپ مەسئۇل، ئاچقۇچى تەرەپ بىلەن مۇناسىۋىتى يوق، قانونسىز مەزمۇن تېڭشۈن تەرەپتىن جازالىنىپ، ئەپچاق تاقالسا، ئاچقۇچى تەرەپ تۇلۇق مۇلازىمەت ھەققى ئېلىپ قايتىدىن سۇپا ئورۇنلاشتۇرۇپ بەرسە بولىدۇ.`,
          `مەزكۇر سۇپىنى ئاچقۇچى تەرەپ ئۈندىدار، QQ، 钉钉 ۋە باشقا ئالاقە ۋاستىلىرىدا ئىشلەتكۈچى بىلەن كېلىشكەن باشقا كېلىشىم مەزمۇنىمۇ ئوخشاشلا قانونى كۈچكە ئېگە.`,
          `مەزكۇر سۇپىنى ئىشلەتكىنىڭىز يۇقارقى كېلىشىمگە قوشۇلغانلىقىڭىزنى بىلدۈرىدۇ. كېلىشىمگە خىلاپ ھالدا ئېلىپ بارغان ھەرقانداق مەشغۇلاتىىڭىز پەقەت ئىشلەتكۈچى بولغان سىزنىڭ مەيدانىڭىز ۋە قىزىقىشىڭىزنى ئىپادىلەيدۇ، ئاچقۇچى تەرەپ بىلەن مۇناسىۋەتسىز.`,
        ]
      }
    }
  }
</script>

<style scoped>

</style>
