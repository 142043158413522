export default {
  methods: {
    showBusy(busyVariable = 'formBusy'){
      this[busyVariable] = true;
    },
    hideBusy(busyVariable = 'formBusy'){
      this[busyVariable] = false;
    }
  }
}
